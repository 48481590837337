import {
  DeploymentStatus,
  Metadata,
  PlugType,
} from '~/components/PluginsAndWebscripts/Common/Types'

export interface IGlobalFilersVisibility {
  resources: boolean
  tasks: boolean
  template: boolean
  plugins: boolean
  resourceTypes: boolean
  webscripts: boolean
  constraints: boolean
}

export interface AlarmHistoryItem {
  id: string
  text: string
  timestamp: string
  type: string
  changes: IChange[]
}

export interface IFailure {
  error: string
  statusCode: number
}

export interface IChange {
  attribute: string
  type: string
  oldValue: AlarmStatus | string
  newValue: AlarmStatus | string
}

export enum AlarmSeverity {
  Warning = 'WARNING',
  Minor = 'MINOR',
  Major = 'MAJOR',
  Critical = 'CRITICAL',
}

export enum AlarmStatus {
  Active = 'ACTIVE',
  Acknowledged = 'ACKNOWLEDGED',
  Cleared = 'CLEARED',
}

export interface IAlarm {
  count: number
  creationTime: string
  history: AlarmHistoryItem[]
  id: string
  self: string
  severity: AlarmSeverity
  source: {
    id: string
  }
  status: AlarmStatus
  text: string
  timestamp: string
  type: string
  lastTriggeredTime: string
  lastUpdateTime: string
}

export enum TaskType {
  OneTime = 'onetime',
  Periodic = 'periodic',
  Scheduled = 'scheduled',
  Reactive = 'reactive',
}

export enum TaskStatus {
  Running = 'running',
  Stopped = 'stopped',
  Failed = 'failed',
}

export type FetchedTags = Record<string, string>

export interface IBindingSchema {
  globalSettings: Object
  node: Object
  nodes: Record<string, Object>
  streamdata: {
    timestamp: string
  }
  task: {
    ACTION_NODE: string | null
    NODE_NAME: string | null
    resource: Object
    TASK_ID: string | null
    variables: Record<string, any | null>
    vault: Record<string, string>
  }
}

export enum LogLevel {
  Error = 'ERROR',
  Warning = 'WARN',
  Info = 'INFO',
  Debug = 'DEBUG',
}

export enum WebscriptsLogLevel {
  Critical = 'CRITICAL',
  Error = 'ERROR',
  Warning = 'WARNING',
  Info = 'INFO',
  Debug = 'DEBUG',
}

export interface IStatus {
  name: string
  status: string
  provider: string
  lastMessage: number
  error?: {
    message: string
  }
}

export interface IPlugProperty {
  name: string
  type: PropertyType
  dataType?: PropertyType | EDescriptorType
  mandatory?: boolean
  sensitive?: boolean
  defaultValue: any
  format: {
    type: PropertyFormatType
    values?: Object[]
    strict: boolean
  }
}

export interface PlugV0 {
  type: PlugType
  name: string
  version: string
  author: string
  description: string
  mediaType: 'application/javascript' | 'application/java-vm'
  iconURL: string
  category: string
  configuration: IPlugProperty[]
  isDeprecated?: boolean
  tags: string[]
  metadata?: { tags: IPlugTag[] }
  status: 'running' | 'killed' | 'pending' | 'failed'
}

export interface PlugV2 {
  name: string
  version: string
  runtime: string
  runtimeVersion: string
  metadata: {
    author: string
    description: string
    iconURL: string
    category: string
    tags: IPlugTag[]
    documentation: object
  }
  type: PlugType
  interface: {
    states: string[]
    input: IPlugPropertyV2[]
    output: any[]
  }
}

export interface IPlugPropertyV2 {
  name: string
  dataType: PropertyType
  mandatory?: boolean
  sensitive?: boolean
  defaultValue: any
  format: {
    type: PropertyFormatType
    values?: Object[]
    strict: boolean
  }
}

export interface PlugsV2 {
  count: number
  limit: number
  page?: number
  entities: PlugsV2Entity[]
}

export interface PlugsV2Entity {
  createdAt: string
  createdBy: string
  deprecated: boolean
  draft: boolean
  plug: PlugV2
  runtime: Runtime
  status: DeploymentStatus
  updatedAt: string
  updatedBy: string
}

export interface Webscripts {
  count: number
  limit: number
  entities: WebscriptVersion[]
}

export interface Webscript {
  entity: WebscriptVersion
}

export interface WebscriptState {
  script: string
  dependencies: {} | Dependencies
  details: {
    runtime?: Runtime
    failureReason?: { log: string[] }
    webscript: WebscriptManifest
    secret?: string
  }
  assets: any
  isRestrictedAccessToContent?: boolean
}

export interface Dependencies {
  name: string
  version: string
  dependencies: Record<string, any>
}

export interface WebscriptVersion {
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  status: EWebscriptStatus
  runtime: Runtime
  webscript: WebscriptManifest
  secret?: string
  _links?: {
    invoke?: {
      href?: string
    }
  }
}

export interface WebscriptManifest {
  allowHmac?: boolean
  private: boolean
  metadata: Metadata
  name: string
  upgradable?: boolean
  version: string
  runtime: string
  runtimeVersion?: string
  protected?: boolean
}

export interface Runtime {
  deprecated?: boolean
  name: string
  upgradable?: boolean
  version: string
}

export interface IWebscriptPlugProperties {
  private: boolean
}

export enum EWebscriptStatus {
  Running = 'running',
  Pending = 'pending',
  Failed = 'failed',
  Undeploying = 'undeploying',
  Undeployed = 'undeployed',
  Killed = 'killed',
}

export enum Status {
  enabled = 'enabled',
  disabled = 'disabled',
  unverified = 'unverified',
}

export interface IUser {
  id: string
  email: string
  firstname: string
  lastname: string
  fullname: string
  timezone: string
  status: Status
  twofa: boolean
  roles: IRole[]
  /* eslint-disable camelcase */
  created_at: string
  updated_at: string
  last_login: string
  /* eslint-enable camelcase */
}

export interface ITenant {
  id: string
  name: string
  domain: string
  description: string
  status: Status
  twofa: boolean
}

export interface IRole {
  id?: string
  name: string
  description: string
  tenant: ITenant
  permissions: IPermission[]
  /* eslint-disable camelcase */
  created_at: string
  updated_at: string
  /* eslint-enable camelcase */
  predefined?: boolean
}

export enum PermissionScope {
  all = 'all',
  me = 'me',
}

export enum PermissionOperation {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
}

export interface IPermission {
  id: string
  name: string
  description: string
  scope: PermissionScope
  operation: PermissionOperation
}

export interface Operation {
  name: string
  metadata: object
  done: boolean
  error: {
    code: number
    message: string
    details: object
  }
  response: object
}

export interface WebscriptOperation {
  name: string
}

export interface IJsonWebTokenBody {
  tenant?: string
  licenses?: string[]
  exp?: number
  domain?: string
  groups?: string[]
  permissions?: string[]
  iat?: number
  iss?: string
  sub?: string
}

export enum PropertyType {
  String = 'string',
  Integer = 'integer',
  Double = 'double',
  Object = 'object',
  Array = 'array',
  Boolean = 'boolean',
  Long = 'long',
  Float = 'float',
  // TODO Primitive or advanced type??
}

export enum EDescriptorType {
  PLUGIN = 'aiPluginDescriptor',
  TEMPLATE = 'aiTemplateDescriptor',
}
export interface ISetting {
  key: string
  value?: string
}

export enum PropertyFormatType {
  Enum = 'enum',
  Resource = 'resource',
  Vault = 'vault',
  Duration = 'duration',
  Template = 'template',
  AiTemplateDescriptor = 'aiTemplateDescriptor',
  AiPluginDescriptor = 'aiPluginDescriptor',
}

export interface IValidationDetailError {
  type: string
  message: string
  severity: 'ERROR' | 'WARNING'
  details: {
    nodeId: string
    actuatorLabel: string
    plugin: Object
    property?: string
    binding?: string
    cycle?: string[]
  }
  suggestion?: string
}

export interface IPlugTag {
  color: string
  name: string
}

export enum WebscriptSeverity {
  Default = 'DEFAULT',
  Debug = 'DEBUG',
  Notice = 'NOTICE',
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR',
  Critical = 'CRITICAL',
  Alert = 'ALERT',
  Emergency = 'EMERGENCY',
}

export enum WebscriptMethods {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

export enum ExplorerVisualizationType {
  CHART = 'chart',
  TABLE = 'table',
}

export enum LicenseAlarmType {
  EXPIRES_SOON = 'EXPIRES_SOON',
  EXPIRED_OR_MISSING = 'EXPIRED_OR_MISSING',
  EXCEEDING_LIMITS = 'EXCEEDING_LIMITS',
}

export enum LicenseAlarmLocation {
  GENERAL = 'general',
  SPECIFIC_TAB = 'specific_tab',
}

export enum LicenseMetricsMapping {
  resources = 'resources',
  transactions = 'transactions',
  parallelExecutions = 'parallel executions',
}

export interface BreachedMetric {
  name: string
  value: number
  breached: boolean
}

export enum BatchEntity {
  resource = 'resource',
  resourceType = 'resourcetype',
  task = 'task',
}

// Matches entities/types in waylay-js
export enum BatchType {
  resource = 'resources',
  resourceType = 'types',
  task = 'tasks',
  alarm = 'alarms',
}

export enum BatchAction {
  delete = 'delete',
  start = 'start',
  restart = 'restart',
  stop = 'stop',
  reload = 'reload',
  update = 'update',
}

export interface IBatchSuccess {
  statusCode: number
  uri: string
  entity: {
    id: string
    queueTime: string
    operation: {
      entity: BatchEntity
      action: BatchAction
      description: string
    }
  }
}

export interface IBatchFailure {
  statusCode: number
  error: string
}

export interface IBatchOperation {
  id: string
  user: string
  operation: {
    entity: BatchEntity
    action: BatchAction
    message: string
  }
  queueTime: string
  finishedTime: string
  results: {
    success: BatchResult
    failure: BatchResult
  }
}

export interface BatchResult {
  [entityId: string]: {
    statusCode: number
    error?: string
  }
}

export enum WaylayEntity {
  Alarm = 'Alarm',
  Resource = 'Resource',
  ResourceType = 'ResourceType',
  Constraint = 'Constraint',
  Plugin = 'Plugin',
  Task = 'Task',
  Template = 'Template',
  Webscript = 'Webscript',
  Storage = 'Storage',
  DataQuery = 'DataQuery',
  BYOML = 'BYOML',
  Subflow = 'Subflow',
}

export interface IRawDataProperty {
  dataType: PropertyType
  name: string
}

export interface IErrorResponse extends Error {
  response: {
    data: {
      error: string
      status: number
      details?: any[]
    }
  }
}

export interface IResponse {
  statusCode: number
  uri: string
  entity: {
    id: string
    name: string
  }
}

interface ITaskDefaults {
  rrule?: string
  cron?: string
  [key: string]: any
}

export interface ITemplate {
  name?: string
  prettyName?: string
  fields?: object[]
  variables?: any[]
  taskDefaults?: ITaskDefaults
}

export enum AdditionalPropertyFormatType {
  plugin = 'plugin',
  version = 'version',
}

export enum EDescriptorView {
  DEBUG = 'DEBUG',
  EDITOR = 'EDITOR',
}

export interface IDescriptor {
  functionName: string
  description: string
  parameters: IDescriptorParameter[]
}

export interface IDescriptorParameter {
  name: string
  description: string
  type: PropertyType
  overrideValue?: any
  required: boolean
  hide?: boolean
}

export interface ISelectOption {
  label: string
  value: any
}

export enum ESortOrder {
  asc = 'asc',
  desc = 'desc',
}

export interface ISort {
  by: string
  order: ESortOrder
}

export enum ESortOrderLong {
  asc = 'ascending',
  desc = 'descending',
}

export interface IGlobalFilter {
  id: string
  name: string
  filter: string
  isActive?: boolean
  wql?: string
}
