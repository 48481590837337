import { isEmpty, get } from 'lodash-es'
import client from '../../../lib/client'
import useSearchQuery from '../../../hooks/useSearch'
import { RESOURCES_LIST_KEY } from '~/lib/QueryKeys'
import { EType, ResourceManagement } from '../Resources.types'
import { useNavigate } from 'react-router'
import { IResponse } from '~/lib/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import GlobalFiltersContainer from '~/hooks/useGlobalFilters'
import { useMemo } from 'react'

interface IUseResourcesProps {
  limit: number
  skip: number
}

const fetchResources = async (filter: object, opts: object) => {
  const data = await client.resources.search(filter, {
    headers: { Accept: 'application/hal+json' },
    ...opts,
  })
  return {
    items: get(data, '_embedded.values', []) as ResourceManagement[],
    totalResults: get(data, 'total', 0) as number,
    numberOfResults: get(data, '_embedded.values', []).length,
  }
}

const fetchResourceTypesFn = async ({
  limit,
  skip,
  text,
  filters,
  query,
  opts,
}: {
  limit: number
  skip: number
  text?: string
  filters?: object
  query?: string | string[]
  opts?: object
}) => {
  const params = {
    limit,
    skip,
    filter: text,
    ...filters,
  }

  if (query) {
    // @ts-expect-error
    params.query = query
  }
  const data = await client.types.list(params, opts)

  return {
    items: get(data, 'values', []) as ResourceManagement[],
    totalResults: get(data, 'total', 0) as number,
    numberOfResults: get(data, 'values', []).length,
  }
}

const createFn = async (arg: { values: any; type: string }) => {
  const { values, type } = arg
  return client[type].create(values)
}

const useResources = (type: EType, options: IUseResourcesProps) => {
  const { textQuery, search, parsedQuery } = useSearchQuery()
  const { limit, skip } = options ?? {}
  const { filtersVisibility, globalFilter } =
    GlobalFiltersContainer.useContainer()

  const filter =
    type === EType.resource
      ? isEmpty(search) || !isEmpty(textQuery)
        ? { filter: search }
        : { query: search }
      : {
          filters: parsedQuery,
          text: textQuery,
        }

  const opts = useMemo(() => {
    return filtersVisibility[type] && globalFilter
      ? { wql: globalFilter?.wql }
      : undefined
  }, [globalFilter?.wql, filtersVisibility[type]])

  const state = useQuery({
    queryKey: [
      [
        RESOURCES_LIST_KEY,
        JSON.stringify(filter),
        limit,
        skip,
        JSON.stringify(opts),
      ].join(),
    ],
    queryFn: async () => {
      if (type === EType.resource) {
        return await fetchResources({ limit, skip, ...filter }, opts)
      } else {
        return await fetchResourceTypesFn({
          limit,
          skip,
          ...filter,
          opts,
        })
      }
    },
  })

  return {
    state,
  }
}

export const useCreate = (type: EType) => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: createFn,
    onSuccess: (response: IResponse) => {
      navigate(`/${type}/${encodeURIComponent(response.entity.id)}`)
    },
  })
}

export default useResources
