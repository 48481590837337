import React from 'react'
import { Form, watermelon } from '@waylay/react-components'
import { PropertyType } from '~/lib/types'
import { IPropertyInput } from '../../types'

const FLOATING_POINT_TYPES = [PropertyType.Double, PropertyType.Float]

const NumericField = ({
  property,
  value,
  setFieldValue,
  isError,
}: IPropertyInput) => {
  const { name, mandatory, type, dataType, defaultValue } = property
  const resolvedPropertyType = type ?? dataType

  const allowDigits = FLOATING_POINT_TYPES.includes(resolvedPropertyType)

  const handleChange = event => {
    setFieldValue(name, event.target.valueAsNumber)
  }

  return (
    <Form.Input.Group fluid>
      <Form.Input
        style={{ width: '100%', borderColor: isError ? watermelon : '#e6e6e6' }}
        fluid
        required={mandatory && !defaultValue}
        type="number"
        id={name}
        key={name}
        name={name}
        defaultValue={value}
        placeholder={defaultValue}
        step={allowDigits ? 'any' : '1'}
        onChange={handleChange}
      />
    </Form.Input.Group>
  )
}

export default NumericField
