import React from 'react'
import { Toggle } from '@waylay/react-components'
import { IPropertyInput } from '../../types'

const ToggleField = ({
  property,
  value,
  setFieldValue,
  testid,
}: IPropertyInput) => {
  const { name, defaultValue } = property

  const handleChange = event => {
    setFieldValue(name, event.target.checked)
  }

  return (
    <Toggle
      data-testid={`toggle-${testid || name}`}
      checked={value === undefined ? defaultValue : value}
      onChange={handleChange}
    />
  )
}

export default ToggleField
