import { useMemo, useState } from 'react'
import { get } from 'lodash-es'
import { createContainer } from 'unstated-next'
import { useToasts } from 'react-toast-notifications'
import client from '../../../lib/client'
import { WebscriptVersion } from '../../../lib/types'
import { EventSourcePolyfill } from 'event-source-polyfill'
import {
  createFileDownload,
  normalizeForPartialMatch,
  removeEmptyProps,
} from '~/lib/util'
import useSearchQuery from '~/hooks/useSearch'
import usePagination from '~/hooks/usePagination'
import { useMutateQueryPartialKey } from '../../Common/QueryHelpers'
import { useMutation, useQuery } from '@tanstack/react-query'
import GlobalFiltersContainer from '~/hooks/useGlobalFilters'

export const WEBSCRIPTS_KEY = 'WEBSCRIPTS'

export const useWebscripts = () => {
  const { textQuery } = useSearchQuery()
  const { filtersVisibility, globalFilter } =
    GlobalFiltersContainer.useContainer()

  const webscriptsPagination = usePagination({
    watch: [textQuery],
  })
  const { page, limit } = webscriptsPagination

  const opts = useMemo(() => {
    return filtersVisibility.webscripts && globalFilter
      ? { wql: globalFilter?.wql }
      : undefined
  }, [globalFilter?.wql, filtersVisibility.webscripts])

  const key = [WEBSCRIPTS_KEY, JSON.stringify({ textQuery, page, limit }), opts]

  const { data, error, refetch } = useQuery({
    queryKey: key,
    queryFn: () =>
      fetchWebscripts({
        name: normalizeForPartialMatch(textQuery),
        page,
        // @ts-expect-error
        showTags: 'inline',
        limit,
        opts,
      }),
    gcTime: 0,
  })

  const { webscripts = [], count = 0 } = data ?? {}

  const loading = !data && !error

  const reload = () => {
    refetch()
  }

  const remove = useRemoveWebscript()

  const download = useDownload()

  return {
    loading,
    data: webscripts,
    error,
    count,
    reload,
    remove,
    download,
    webscriptsPagination,
  }
}

const fetchWebscripts = async (params: {
  name: string
  page: number
  limit: number
  tags?: { tags?: string[] } | undefined
  opts?: { wql?: string } | undefined
}): Promise<{ webscripts: WebscriptVersion[]; count: number }> => {
  const data = await client.registry.webscripts.list(
    removeEmptyProps({ ...params }),
    params.opts,
  )
  return {
    webscripts: data.entities,
    count: data.count,
  }
}

export const useReload = () => {
  const [reload, setReload] = useState(false)
  return { reload, setReload }
}

const useRemoveWebscript = () => {
  const { addToast } = useToasts()
  const mutateQueryPartialKey = useMutateQueryPartialKey()

  return useMutation({
    mutationFn: async (arg: { webscriptName: string }) => {
      const { webscriptName } = arg
      const deleteResponse = await client.registry.webscripts.removeAll(
        webscriptName,
      )
      return { webscriptName, deleteResponse }
    },

    onSuccess: ({ webscriptName, deleteResponse }) => {
      addToast(`Deleting ${webscriptName} in process`, { appearance: 'info' })
      const eventSource = new EventSourcePolyfill(
        deleteResponse._links.event.href,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
          withCredentials: true,
        },
      )
      eventSource.addEventListener('completed', event => {
        const eventData = JSON.parse(event.data)
        if (
          eventData.job.type === 'batch' &&
          eventData.function.functionType === 'webscripts' &&
          eventData.function.name === webscriptName
        ) {
          mutateQueryPartialKey(WEBSCRIPTS_KEY)
          eventSource.close()
          addToast(`Succesfully deleted ${webscriptName}`, {
            appearance: 'success',
          })
        }
      })
    },
    onError: () => addToast('Something went wrong', { appearance: 'error' }),
    throwOnError: false,
  })
}

const useDownload = () => {
  const { addToast } = useToasts()

  return useMutation({
    mutationFn: async (arg: { webscriptName: string; version: string }) => {
      const { webscriptName, version } = arg
      await client.registry.webscripts.content(
        webscriptName,
        version,
        {},
        {
          headers: { Accept: 'application/gzip' },
        },
      )
    },
    onSuccess: result => {
      createFileDownload(`${result[0]}.gz`, result[1], 'application/gzip')
    },
    onError: error => {
      const message = get(error, 'response.data.error', error.message)
      addToast(`Failed to download: ${message}`, { appearance: 'error' })
    },
    throwOnError: false,
  })
}

export const WebscriptsContainer = createContainer(useWebscripts)
