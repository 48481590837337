import React from 'react'
import { Form, watermelon } from '@waylay/react-components'
import OpenInEditor from '../OpenInEditor'
import { HStack } from '~/components/Common/Stack'
import { IPropertyInput } from '../../types'

const StringField = ({
  property,
  value,
  setFieldValue,
  testid,
  isError,
}: IPropertyInput) => {
  const { name, mandatory, defaultValue } = property

  const handleChange = event => {
    setFieldValue(name, event.target.value)
  }

  return (
    <HStack>
      <Form.Input.Group fluid>
        <Form.Input
          fluid
          required={mandatory && !defaultValue}
          type="text"
          id={name}
          key={name}
          name={name}
          value={value ?? ''}
          placeholder={defaultValue}
          onChange={handleChange}
          style={{ borderColor: isError ? watermelon : '#e6e6e6' }}
          data-testid={`input-${testid || name}`}
        />
      </Form.Input.Group>
      <OpenInEditor
        property={property}
        value={value}
        setFieldValue={setFieldValue}
      />
    </HStack>
  )
}

export default StringField
