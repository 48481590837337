import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useModal } from 'react-modal-hook'
import { Formik } from 'formik'
import {
  Button,
  Icon,
  Form,
  Modal,
  Segment,
  Message,
  Tooltip,
} from '@waylay/react-components'
import ResourceTypeSelect from '~/components/Common/ResourceTypeSelect'
import { IErrorResponse } from '~/lib/types'
import { IfRejected } from '../../../Common/QueryHelpers'
import * as Yup from 'yup'
import { EClientType, ESingularType, EType } from '../../Resources.types'
import Attributes from './Attributes/Attributes'
import ErrorMessage from './ErrorMessage'
import { useCreate } from '../useResources'
import { v4 as createUUID } from 'uuid'

const NewResourceManagementModal = ({ type }: { type: EType }) => {
  const create = useCreate(type)

  const INITIAL_RESOURCE_TYPE = EType.resourceType
    ? { resourceTypeId: undefined }
    : {}
  const [showModal, hideModal] = useModal(
    () => (
      <Modal
        isOpen
        onRequestClose={() => {
          hideModal()
          create.reset()
        }}
      >
        <Formik
          initialValues={{
            name: '',
            id: createUUID(),
            ...INITIAL_RESOURCE_TYPE,
          }}
          onSubmit={values =>
            create.mutate({
              values,
              type: EClientType[type],
            })
          }
          enableReinitialize
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
          })}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit}>
              <Segment.Group
                css={css`
                  width: 600px;
                `}
              >
                <Segment.Header>Create {ESingularType[type]}</Segment.Header>
                <Segment>
                  <Form.Field>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <FormGroup>
                      <Form.Input
                        name="name"
                        fluid
                        autoFocus
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </FormGroup>
                  </Form.Field>
                  <Form.Field>
                    <FormLabel htmlFor="id">Identifier</FormLabel>
                    <FormGroup>
                      <Form.Input
                        name="id"
                        fluid
                        value={formik.values.id}
                        onChange={formik.handleChange}
                      />
                      <Tooltip content="This is the unique identifier of the resource">
                        <Form.Input.Adornment
                          right
                          css={css`
                            display: inline-flex;
                          `}
                        >
                          <Icon name="help" />
                        </Form.Input.Adornment>
                      </Tooltip>
                    </FormGroup>
                  </Form.Field>
                  {type === EType.resource && (
                    <Form.Field>
                      <FormLabel htmlFor="resourceTypeId">
                        Resource type
                      </FormLabel>
                      <ResourceTypeSelect
                        value={(formik.values as any).resourceTypeId}
                        onChange={({ value }) =>
                          formik.setFieldValue('resourceTypeId', value)
                        }
                      />
                    </Form.Field>
                  )}

                  {type === EType.resource && <Attributes />}
                  <div
                    css={css`
                      margin-top: 0.5rem;
                    `}
                  >
                    {Object.values(formik.errors)[0] && (
                      <Message kind="danger">
                        {Object.values(formik.errors)[0]}
                      </Message>
                    )}
                    <IfRejected state={create}>
                      {(error: IErrorResponse) => (
                        <ErrorMessage error={error} />
                      )}
                    </IfRejected>
                  </div>
                </Segment>
                <Modal.Actions>
                  <Button
                    outline
                    kind="secondary"
                    onClick={() => {
                      hideModal()
                      create.reset()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    kind="primary"
                    loading={create.isPending}
                    disabled={create.isPending}
                    onClick={formik.handleSubmit}
                  >
                    Create {ESingularType[type]}
                  </Button>
                </Modal.Actions>
              </Segment.Group>
            </Form>
          )}
        </Formik>
      </Modal>
    ),
    [create.isPending, create.error],
  )

  return {
    showModal,
    hideModal,
  }
}

const FormGroup = styled(Form.Input.Group)`
  display: inline-flex;
  align-items: center;
  width: 100%;
`

const FormLabel = styled.label`
  margin-bottom: 0.5em;
`

export default NewResourceManagementModal
