import { IPlugTag, Runtime } from '~/lib/types'

export enum CustomFunctionsType {
  PLUGIN = 'plug',
  WEBSCRIPT = 'webscript',
}

export enum PlugType {
  Sensor = 'sensors',
  Actuator = 'actuators',
  Transformer = 'transformers',
  Webscripts = 'webscripts',
}

export enum PlugTypeSingular {
  Sensor = 'sensor',
  Actuator = 'actuator',
  Tranformer = 'transformer',
  Webscript = 'Webscript',
  Gate = 'gate',
  Note = 'note',
}

export interface PlugV2Structure {
  assets: any
  dependencies: Record<string, string>
  details: PlugsV2Entity
  script: string
  runtime: any
  isRestrictedAccessToContent?: boolean
}

export interface PlugsV2Entity {
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  status: DeploymentStatus
  failureReason?: any
  deprecated: boolean
  draft: boolean
  plug: PlugV2
  runtime: Runtime
  updates?: any
  _embedded: {
    draft: PlugsV2Entity
  }
}

export interface PlugV2 {
  protected: boolean
  name: string
  version: string
  runtime: string
  runtimeVersion?: string
  metadata: {
    author?: string
    description?: string
    iconURL?: string
    category?: string
    tags?: IPlugTag[]
    friendlyName?: string
    documentation: PlugDocumentation
  }
  type: PlugTypeSingular
  interface: {
    input: InputProperty[]
    output: OutputProperty[]
    states: string[]
  }
}

export interface PlugDocumentation {
  input: Array<{ name: string; description?: string }>
  output: Array<{ name: string; description?: string }>
  states: Array<{ name: string; description?: string }>
}

export interface InputProperty {
  name: string
  dataType?: TypeEnum
  mandatory?: boolean
  sensitive?: boolean
  defaultValue?: string
  format?: FormatType
}

export interface OutputProperty {
  name: string
  dataType?: TypeEnum
  mandatory?: boolean
  default?: string
  format?: FormatType
}

export interface PlugsV2 {
  count: number
  limit: number
  page?: number
  entities: PlugsV2Entity[]
}

export enum DeploymentStatus {
  running = 'running',
  pending = 'pending',
  deployed = 'deployed',
  unhealthy = 'unhealthy',
  killed = 'killed',
  failed = 'failed',
  undeploying = 'undeploying',
  undeployed = 'undeployed',
}
export enum FormatType {
  enum = 'enum',
  resource = 'resource',
  vault = 'vault',
  duration = 'duration',
  url = 'url',
  date = 'date',
  template = 'template',
}
export enum TypeEnum {
  string = 'string',
  integer = 'integer',
  long = 'long',
  float = 'float',
  double = 'double',
  boolean = 'boolean',
  object = 'object',
}

export enum PluginType {
  Sensor = 'sensor',
  Actuator = 'actuator',
  Transformer = 'transformer',
}

export interface Metadata {
  author: string
  description: string
  iconURL: string
  tags?: string[]
}

export enum CardType {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
  DRAFT_AND_PUBLISHED = 'Both',
}

export enum TabType {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
}

export enum AvailableActions {
  DOWNLOAD = 'download',
  DEPRECATE_ALL = 'deprecateAll',
  DEPRECATE = 'deprecate',
  DELETE_ALL = 'deleteAll',
  DELETE = 'delete',
  COPY_AS = 'copyAs',
  PUBLISH = 'publish',
  TAKE_OWNERSHIP = 'takeOwnership',
  DELETE_DRAFT = 'deleteDraft',
  PROTECT_ALL = 'protectALL',
  UNPROTECT_ALL = 'unprotectAll',
}

export enum DraftType {
  OWNED = 'ownedByCurrentUser',
  NOT_OWNED = 'notOwnedByCurrentUser',
}

export enum PublishedType {
  OWNED = 'ownedByCurrentUser',
  NOT_OWNED = 'notOwnedByCurrentUser',
}

export enum PluginStatusOwnership {
  PUBLISHED = 'published',
  DRAFT_OWNED = 'draftOwner',
  DRAFT_NOT_OWNER = 'draftNotOwner',
}

export enum ComponentLocation {
  HEADER = 'header',
  LEGACY = 'legacy',
  PLUGINS_LIST = 'pluginsList',
  VERSIONS_LIST = 'versionsList',
}

export enum ListItemType {
  DANGER = 'danger',
  NORMAL = 'normal',
  WARNING = 'warning',
}
