import React from 'react'
import { Icon, watermelon } from '@waylay/react-components'
import { isEmpty } from 'lodash-es'

const SmallError = ({
  error,
  showDiv = true,
}: {
  showDiv?: boolean
  error?: string
}) => {
  return (
    <>
      {!isEmpty(error) && (
        <>
          {showDiv && <div />}
          <div
            style={{
              color: watermelon,
              display: 'flex',
              alignItems: 'center',
              gap: 5,
            }}
          >
            <Icon name="error_outline" style={{ fontSize: '14px' }} />
            <div>{error}</div>
          </div>
        </>
      )}
    </>
  )
}

export default SmallError
