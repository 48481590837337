let navigate

export const setNavigate = navigateFn => {
  navigate = navigateFn
}

export const getNavigate = () => {
  if (!navigate) {
    throw new Error('Navigate function is not initialized.')
  }
  return navigate
}
