import React, { useEffect, useState } from 'react'
import { Button, Icon, Modal, Segment, Tooltip } from '@waylay/react-components'
import styled from '@emotion/styled'
import { useModal } from 'react-modal-hook'
import { ReQuartzCron } from '@sbzen/re-cron'
import cron from 'cron-validate'
import cronstrue from 'cronstrue'

const CronWidget = ({ initialValue, onSave }) => {
  const [cronValue, setCronValue] = useState('* * * * * *')

  const validateCronValue = () => {
    if (initialValue) {
      const cronObject = cron(initialValue, {
        override: { useBlankDay: true, useSeconds: true, useYears: true },
      }).isValid()

      if (cronObject) {
        setCronValue(initialValue)
      }
    }
  }

  useEffect(() => {
    validateCronValue()
  }, [initialValue])

  const [showModal, hideModal] = useModal(
    () => (
      <Modal isOpen onRequestClose={hideModal}>
        <div style={{ width: '900px' }}>
          <Segment.Group data-testid="modal-content">
            <Segment.Header data-testid="modal-header">
              <b>Cron Editor</b>
            </Segment.Header>
            <Segment>
              <CodeContainer className="py-2" data-testid="code-content">
                <b>Cron Value: </b>
                <Tooltip
                  placement="bottom"
                  content={
                    <span
                      style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                      {cronValue
                        ? cronstrue.toString(cronValue)
                        : 'Invalid cron expression'}
                    </span>
                  }
                >
                  <code
                    data-testid="code-value"
                    style={{ wordWrap: 'break-word', color: '#e83e8c ' }}
                  >
                    {cronValue}
                  </code>
                </Tooltip>
              </CodeContainer>
              <div className="my-cron">
                <ReQuartzCron
                  value={cronValue}
                  onChange={value => setCronValue(value)}
                />
              </div>
            </Segment>
            <Modal.Actions>
              <ButtonWrapper>
                <Button
                  outline
                  kind="secondary"
                  onClick={() => hideModal()}
                  style={{ margin: '0 5px' }}
                  data-testid="cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  data-testid="save-button"
                  kind="primary"
                  onClick={() => {
                    onSave(cronValue)
                    hideModal()
                  }}
                  style={{ margin: '0 5px' }}
                >
                  Save
                </Button>
              </ButtonWrapper>
            </Modal.Actions>
          </Segment.Group>
        </div>
      </Modal>
    ),
    [cronValue],
  )

  return (
    <div>
      <Button
        onClick={() => {
          validateCronValue()
          showModal()
        }}
        data-testid="open-editor-button"
        style={{ height: '100%' }}
      >
        <Icon name="launch" data-testid="open-editor-button-icon" /> Open editor
      </Button>
    </div>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const CodeContainer = styled.div`
  max-width: 800px;
  background: white;
  border: 1px solid rgb(232, 232, 232);
  box-shadow: rgba(37, 11, 54, 0.04) 0px 2px 0px;
  border-radius: 0.333333rem;
  padding: 0.3em;
  margin-bottom: 14px;
`

export default CronWidget
