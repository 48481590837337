import React, { useEffect, useMemo, useState } from 'react'
import { useModal } from 'react-modal-hook'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isEmpty, omit, cloneDeep, isEqual, pick } from 'lodash-es'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Modal, Segment, Wizard } from '@waylay/react-components'
import VariablesInput from '~/components/Tasks/TemplateVariablesForm'
import { hasDuplicates, isRruleExpr } from '~/lib/util'
import { TaskConfiguration, Errors } from './TaskFormComponents'
import {
  createOrUpdateTaskDeferred,
  templateFetch,
  getYupSchemaFor,
  isTemplateVariable,
} from './TaskFormUtils'
import { ITemplate, TaskType } from '~/lib/types'
import {
  ITag,
  ITaskOptions,
  ITemplateVariable,
  TaskVariable,
} from '../../types'
import { EMPTY_TAGS } from '~/lib/Constants'
import { useMutation, useQuery } from '@tanstack/react-query'

// tags are always prefilled with empty values
interface ITaskFormProps {
  template?: string
  network?: any
  task?: any
  refetchTask?: any
}

export default function useTaskFormModal({
  task = {},
  template: propTemplate = task.template,
  refetchTask,
}: ITaskFormProps) {
  const navigate = useNavigate()
  const [selectedTemplate, setSelectedTemplate] = useState(propTemplate)

  const createOrUpdateTask = useMutation({
    mutationFn: createOrUpdateTaskDeferred,
    onSuccess: (response: { ID: string }) => {
      refetchTask?.()
      hideModal()
      navigate(`/tasks/${response.ID}/debug`)
    },
  })

  const { data: template = { taskDefaults: {}, variables: [] } } =
    useQuery<ITemplate>({
      queryKey: ['template-key', selectedTemplate],
      queryFn: () => {
        return templateFetch(selectedTemplate)
      },
      gcTime: 0,
    })

  const validationSchema = useMemo(() => {
    // template variables can be mandatory
    const variableValidationEntries =
      template?.variables?.map(
        ({ name, displayName, mandatory, type, defaultValue }) => {
          const variableIdentifier = `variable "${displayName ?? name}"`
          const schema = getYupSchemaFor(
            type,
            mandatory && !defaultValue,
            variableIdentifier,
          ).typeError(`${variableIdentifier} must be of type ${type}`)
          return [name, schema]
        },
      ) || []

    return Yup.object().shape({
      name: Yup.string().required(),
      // New task from Template Details screen -> template must be passed via task prop
      // New task from Task List screen -> template not passed via task prop => dropdown required
      // Edit task with template -> template must be passed via task prop
      // Edit task that uses network graph -> template not required (task has no associated template)
      template:
        !task.ID && !selectedTemplate ? Yup.string().required() : Yup.string(),
      resource: Yup.string(),
      type: Yup.string().oneOf(Object.values(TaskType)),
      tags: Yup.array()
        .test(
          'key-is-required-for-value',
          'Some values of tags are filled in, but no keys are provided',
          (tags: ITag[]) => {
            if (isEqual(tags, EMPTY_TAGS)) {
              return true
            }

            const emptyKeysForFilledValues = tags.filter(
              ({ key, value }) => !key && value,
            )
            return emptyKeysForFilledValues.length === 0
          },
        )
        .test(
          'no-duplicate-keys',
          'Tags contain duplicate keys',
          (tags: ITag[]) => !hasDuplicates(tags.map(({ key }) => key)),
        ),

      schedule: Yup.string(),
      frequency: Yup.number(),
      parallel: Yup.boolean(),
      resetObservations: Yup.boolean(),
      gatesNeedFullObservation: Yup.boolean(),

      variables: Yup.object().shape(
        Object.fromEntries(variableValidationEntries),
      ),
    })
  }, [JSON.stringify(template), task.ID])

  const initialValues: Record<any, any> = {}

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    onSubmit: async values => {
      const {
        type,
        resource,
        resetObservations,
        schedule,
        frequency,
        parallel,
        gatesNeedFullObservation,
        tags,
        variables,
      } = values

      const { ID } = task
      const templateProp = propTemplate || values.template

      const taskOptions: ITaskOptions = {
        ...(ID && { ID }),
        name: values.name,
        ...(templateProp
          ? { template: templateProp }
          : task.network
          ? { network: task.network }
          : {}),
        type,
        resource,
        // only for periodic tasks
        frequency: type === TaskType.Periodic ? frequency : undefined,
        resetObservations,
        gatesNeedFullObservation,
        parallel,
        tags,
      }

      if (TaskType.Scheduled === type) {
        if (isRruleExpr(schedule)) {
          taskOptions.rrule = schedule
        } else {
          taskOptions.cron = schedule
        }
      }
      const formattedVariables = cloneDeep(variables)

      template?.variables?.forEach(({ name, defaultValue }) => {
        if (
          formattedVariables[name] === '' ||
          formattedVariables[name] === undefined ||
          formattedVariables[name] === null
        ) {
          if (
            defaultValue !== '' &&
            defaultValue !== undefined &&
            defaultValue !== null
          ) {
            formattedVariables[name] = defaultValue
          } else {
            delete formattedVariables[name]
          }
        }
      })

      createOrUpdateTask.mutate({
        template,
        options: taskOptions,
        variables: formattedVariables,
      })
    },
    validationSchema,
  })

  // init the form defaults
  const INITIAL_FORM_VALUES = {
    name: '',
    resource: '',
    type: TaskType.Periodic,
    tags: EMPTY_TAGS,
    schedule: task.rrule ?? task.cron ?? '0 0/15 0 ? * * *',
    frequency: task.frequency ?? 900000,
    ...omit(task, ['cron', 'rrule']),

    /**
     * Advanced settings
     * If no task is passed, set defaults for console (true, false). If a task
     * is passed via props and it doesn't include one of the attributes,
     * replicate engine default behaviour (true, true)
     */
    parallel: isEmpty(task) ? true : task.parallel ?? true,
    resetObservations: isEmpty(task) ? true : task.resetObservations ?? true,
    gatesNeedFullObservation: isEmpty(task)
      ? false
      : task.gatesNeedFullObservation ?? false,
  }

  useEffect(() => {
    const variables: ITemplateVariable[] | TaskVariable =
      task?.variables || template?.variables

    // if the task has a ID we do an edit and no defaults from template should be taken.
    const taskDefaults: { [key: string]: any } = task?.ID
      ? {}
      : omit(template?.taskDefaults, ['cron', 'rrule'])
    if (!isEmpty(taskDefaults)) {
      taskDefaults.schedule =
        template?.taskDefaults?.rrule || template?.taskDefaults?.cron
    }

    const valuesToKeep = pick(formik.values, ['name', 'template'])

    formik.resetForm()
    formik.setValues({
      ...INITIAL_FORM_VALUES,
      ...taskDefaults,
      ...valuesToKeep,
      variables: isTemplateVariable(variables)
        ? Object.fromEntries(variables.map(({ name }) => [name, '']))
        : variables,
    })
  }, [JSON.stringify(template), JSON.stringify(task)])

  const steps = [
    <TaskConfiguration
      key="task_configuration"
      task={task}
      formik={formik}
      propTemplate={propTemplate}
      setSelectedTemplate={setSelectedTemplate}
    />,
    !isEmpty(template.variables) && (
      <VariablesInput
        key="variables_input"
        variableSpecs={template.variables}
        getFieldProps={formik.getFieldProps}
        setFieldValue={formik.setFieldValue}
      />
    ),
  ].filter(Boolean) // filters out the 'variables' part of the task when it's undefined

  const [showModal, hideModal] = useModal(
    () => (
      <Modal isOpen onRequestClose={hideModal}>
        <Form onSubmit={formik.handleSubmit}>
          <Wizard steps={steps}>
            {({ currentStep, next, previous, isFirst, totalSteps, isLast }) => (
              <Segment.Group style={{ width: '600px' }}>
                {currentStep}
                <Errors
                  errors={formik.errors}
                  asyncState={createOrUpdateTask}
                />
                <Segment.Footer style={{ display: 'flex' }}>
                  <div style={{ flexGrow: 1 }}>
                    <Button outline kind="secondary" onClick={hideModal}>
                      Cancel
                    </Button>
                  </div>
                  {totalSteps !== 1 && (
                    <Button
                      disabled={isFirst}
                      onClick={e => {
                        e.preventDefault()
                        previous()
                      }}
                      outline
                    >
                      Previous
                    </Button>
                  )}
                  {!isLast ? (
                    <Button
                      disabled={isLast}
                      onClick={e => {
                        e.preventDefault()
                        next()
                      }}
                      style={{ marginLeft: '5px' }}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      kind="primary"
                      loading={createOrUpdateTask.isPending}
                      disabled={createOrUpdateTask.isPending}
                    >
                      {task.ID ? 'Update task' : 'Create task'}
                    </Button>
                  )}
                </Segment.Footer>
              </Segment.Group>
            )}
          </Wizard>
        </Form>
      </Modal>
    ),
    [createOrUpdateTask, formik.values, formik.errors, formik.handleSubmit],
  )

  return {
    showModal,
    hideModal,
  }
}
