import React from 'react'

import EnumField from './PropertyFields/EnumField'
import ResourceField from './PropertyFields/ResourceField'
import VaultField from './PropertyFields/VaultField'
import NumericField from './PropertyFields/NumericField'
import StringField from './PropertyFields/StringField'
import ToggleField from './PropertyFields/ToggleField'
import ObjectField from './PropertyFields/ObjectField'
import TemplateField from './PropertyFields/TemplateField'
import { get } from 'lodash-es'
import {
  AdditionalPropertyFormatType,
  PropertyFormatType,
  PropertyType,
} from '~/lib/types'
import { IPropertyInput } from '../types'
import PluginSelect from '~/components/Common/PluginSelect'
import VersionsSelect from '~/components/Common/VersionSelect'
import Descriptor from './PropertyFields/Descriptor'

const PropertyInput = (props: IPropertyInput) => {
  // use Waylay type or native type, whichever fits first.
  const type = get(
    props.property,
    'format.type',
    get(props.property, 'dataType', props.property.type),
  )
  const InputField = getInputField(type)

  return <InputField {...props} />
}

function getInputField(
  type: PropertyFormatType | PropertyType | AdditionalPropertyFormatType,
) {
  switch (type) {
    // ai specific types
    case PropertyFormatType.AiPluginDescriptor:
    case PropertyFormatType.AiTemplateDescriptor:
      return Descriptor
    // waylay specific types
    case PropertyFormatType.Enum:
      return EnumField
    case PropertyFormatType.Resource:
      return ResourceField
    case PropertyFormatType.Template:
      return TemplateField
    case PropertyFormatType.Vault:
      return VaultField
    // workaround for tm forum
    case AdditionalPropertyFormatType.plugin:
      return PluginSelect
    case AdditionalPropertyFormatType.version:
      return VersionsSelect

    // native input types
    case PropertyType.Integer:
    case PropertyType.Long:
    case PropertyType.Double:
    case PropertyType.Float:
      return NumericField
    case PropertyType.Boolean:
      return ToggleField
    case PropertyType.Object:
    case PropertyType.Array:
      return ObjectField
    case PropertyType.String:
    default:
      return StringField
  }
}

export default PropertyInput
