import React, { useRef } from 'react'
import { Button, Icon, Modal, Segment } from '@waylay/react-components'
import { useModal } from 'react-modal-hook'
import { css } from '@emotion/core'
import { Rnd } from 'react-rnd'
import Editor from '~/components/Common/Editor'
import { useSafeEditorContainer } from './EditorContext'
import { PropertyType } from '~/lib/types'
import { IProperty, IPropertyInput } from '../types'
function isObject(property: IProperty) {
  return (
    property.type === PropertyType.Object ||
    property.type === PropertyType.Array
  )
}
interface IOpenInEditor extends IPropertyInput {
  supportedLanguages?: string[]
}
const OpenInEditor = ({
  setFieldValue: _setFieldValue,
  property,
  value = '',
  supportedLanguages,
  isDeclarativelyBound,
}: IOpenInEditor) => {
  const editorRef = useRef(null)
  const editorSyntaxes = isObject(property)
    ? isDeclarativelyBound
      ? ['declarative-binding', 'json']
      : ['json']
    : supportedLanguages
  const editorContainer = useSafeEditorContainer()
  const declarativeBindingSchema = editorContainer
    ? editorContainer.declarativeBindingSchema
    : null
  const setFieldValue = (value: any) => {
    if (isObject(property)) {
      try {
        _setFieldValue(JSON.parse(property.name), value)
      } catch (_) {
        _setFieldValue(property.name, value)
      }
      return
    }
    _setFieldValue(property.name, value)
  }
  const saveContent = () => {
    const value = editorRef.current.editor.getValue()
    setFieldValue(value)
    hideModal()
  }
  const [openModal, hideModal] = useModal(() => {
    return (
      <Modal isOpen onRequestClose={saveContent} shouldCloseOnEsc={false}>
        <Rnd
          cancel=".editor"
          minHeight={200}
          minWidth={300}
          default={{
            x: -295,
            y: 0,
            width: 600,
            height: 500,
          }}
        >
          <Segment.Group
            css={css`
              width: 100%;
              height: 100%;
            `}
          >
            <Segment.Header>
              <span
                css={css`
                  flex: 1;
                  height: 15px;
                `}
              >
                Editor · {property.name}
              </span>
              <Button
                size="small"
                css={css`
                  align-self: flex-end;
                `}
                onClick={saveContent}
              >
                <Icon name="save" />
                {property?.buttonText ? property.buttonText : ' Save'}
              </Button>
            </Segment.Header>
            <Segment
              className="editor"
              padding={0}
              css={css`
                height: 90%;
                display: flex;
              `}
            >
              <Editor
                ref={editorRef}
                script={value}
                context={{ declarativeBindingSchema }}
                supportedLanguages={editorSyntaxes}
              />
            </Segment>
          </Segment.Group>
        </Rnd>
      </Modal>
    )
  }, [property, value, JSON.stringify(declarativeBindingSchema)])
  return (
    <div style={{ height: '100%' }}>
      <Button
        kind="secondary"
        outline
        style={{ marginRight: 0, marginLeft: 5 }}
        onClick={() => openModal()}
      >
        <Icon name="code" />
      </Button>
    </div>
  )
}
export default OpenInEditor
