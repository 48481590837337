import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import PropertyInput from '~/components/Tasks/Editor/PropertyInput'
import { Segment, Button, Modal } from '@waylay/react-components'
import { PropertyFormatType } from '~/lib/types'
import { css } from '@emotion/core'
import { cloneDeep, isEmpty, isNaN } from 'lodash-es'
import {
  Descriptor,
  DescriptorExecutionMode,
  DescriptorExecutionOutput,
} from '@waylay/genai-tools/dist/types/types'
import { TitleRight } from '../Debug'
import { descriptor as aiToolsDescriptor } from '@waylay/genai-tools'
import { SmallErrorMessage } from '~/components/Settings/Settings.styles'
import DescriptorParameters from './DescriptorParameters'
import { PLUGIN_EXCLUDED, TEMPLATE_EXCLUDED } from '~/lib/Constants'
import { useToasts } from 'react-toast-notifications'
import SmallError from './SmallError'
import * as yaml from 'js-yaml'
interface IDescriptorPropertiesProps {
  setFieldValue: Function
  setShouldShowDescriptor?: Function
  descriptor: Descriptor
  shouldShowDescriptor?: boolean
  descriptorName: string
  property: any
  revalidateDescriptor: boolean
  setRevalidateDescriptor: Function
}
const DescriptorProperties = ({
  setFieldValue,
  setShouldShowDescriptor,
  descriptor,
  shouldShowDescriptor,
  descriptorName,
  property,
  revalidateDescriptor,
  setRevalidateDescriptor,
}: IDescriptorPropertiesProps) => {
  const [editableDescriptor, setEditableDescriptor] = useState(undefined)
  const [errors, setErrors] = useState(null)
  const [maxHeight, setMaxHeight] = useState(300)
  const { addToast } = useToasts()
  const errorDivRef = useRef(null)
  const [paddingBottom, setPaddingBottom] = useState(20)

  useEffect(() => {
    if (descriptor !== undefined && descriptor !== null) {
      if (typeof descriptor === 'string') {
        try {
          const desc = yaml.load(descriptor as unknown as string)
          setEditableDescriptor(desc)
        } catch (err) {
          addToast('Internal error', { appearance: 'error' })
        }
      }
      if (typeof descriptor === 'object') {
        setEditableDescriptor(descriptor)
      }
    } else {
      setEditableDescriptor(null)
    }
  }, [descriptor])

  useEffect(() => {
    const updateMaxHeight = () => {
      const viewportHeight = window.innerHeight
      const calculatedMaxHeight = viewportHeight * 0.7
      setMaxHeight(calculatedMaxHeight)
    }
    updateMaxHeight()
    window.addEventListener('resize', updateMaxHeight)
    return () => {
      window.removeEventListener('resize', updateMaxHeight)
    }
  }, [])

  const setDescriptorParameters = (
    parameterName: string,
    field: string,
    value: string,
  ) => {
    const newDescriptor = cloneDeep(editableDescriptor)
    const newParameters = newDescriptor.parameters
    const index = newParameters.findIndex(param => param.name === parameterName)

    newParameters[index] =
      field === 'overrideValue'
        ? {
            ...newParameters[index],
            [field]: value,
          }
        : field === 'items'
        ? {
            ...newParameters[index],
            items: {
              ...newParameters[index].items,
              type: value,
            },
          }
        : {
            ...newParameters[index],
            [field]: {
              ...newParameters[index][field],
              value,
            },
          }
    setEditableDescriptor(newDescriptor)
  }
  const removeDescriptorParameters = (parameterName: string, field: string) => {
    const newDescriptor = cloneDeep(editableDescriptor)
    const newParameters = newDescriptor.parameters
    const index = newParameters.findIndex(param => param.name === parameterName)
    delete newParameters[index][field]
    setEditableDescriptor(newDescriptor)
  }

  useEffect(() => {
    if (errorDivRef?.current) {
      const offsetHeight = errorDivRef.current.offsetHeight
      setPaddingBottom(
        (typeof offsetHeight === 'number' ? offsetHeight : 0) + 40,
      )
    } else {
      setPaddingBottom(30)
    }
  }, [errors])

  const deleteDescriptor = () => {
    setFieldValue(descriptorName, null)
    if (typeof setShouldShowDescriptor === 'function')
      setShouldShowDescriptor(false)
    setErrors(null)
  }

  const changeProperty = (name: string, value: string) => {
    const newDescriptor = cloneDeep(editableDescriptor)
    if (name === 'executionMode' && value === DescriptorExecutionMode.async) {
      delete newDescriptor.executionOutput.value
    }
    if (name === 'executionMode' && value === DescriptorExecutionMode.sync) {
      newDescriptor.executionOutput.value = DescriptorExecutionOutput.result
    }
    if (newDescriptor[name]) {
      newDescriptor[name].value = value === '' ? null : value
    }
    setEditableDescriptor(newDescriptor)
  }

  useEffect(() => {
    if (editableDescriptor) {
      try {
        const newDescriptor = formatDescriptor(editableDescriptor)
        aiToolsDescriptor.validate(newDescriptor)
        setErrors(null)
      } catch (err) {
        try {
          const newErrors = JSON.parse(err?.message)
          setErrors(newErrors)
        } catch (err) {
          addToast('Internal error', { appearance: 'error' })
        }
      }
    }
    if (revalidateDescriptor) {
      setRevalidateDescriptor(false)
    }
  }, [
    shouldShowDescriptor,
    descriptorName,
    editableDescriptor,
    revalidateDescriptor,
  ])

  const formatDescriptor = (descriptor: Descriptor) => {
    const formattedDescriptor = cloneDeep(descriptor)
    formattedDescriptor?.parameters?.forEach(parameter => {
      if (
        parameter.overrideValue === undefined ||
        parameter.overrideValue === '' ||
        parameter.overrideValue === null
      ) {
        delete parameter.overrideValue
      }
      if (
        (parameter.type === 'integer' ||
          parameter.type === 'double' ||
          parameter.type === 'long' ||
          parameter.type === 'float') &&
        isNaN(parameter.overrideValue)
      ) {
        delete parameter.overrideValue
      }
    })
    return formattedDescriptor
  }

  const save = () => {
    const formattedDescriptor = formatDescriptor(editableDescriptor)
    setFieldValue(descriptorName, yaml.dump(formattedDescriptor))
    try {
      aiToolsDescriptor.validate(formattedDescriptor)
      setErrors(null)
      addToast('Successfully saved descriptor', { appearance: 'success' })
    } catch (err) {
      try {
        const newErrors = JSON.parse(err?.message)
        setErrors(newErrors)
      } catch (err) {
        addToast('Internal error', { appearance: 'error' })
      }
    }
  }

  const close = () => {
    setErrors(null)
    setShouldShowDescriptor(false)
  }

  return (
    <div>
      {shouldShowDescriptor &&
        !isEmpty(editableDescriptor) &&
        descriptorName && (
          <Segment.Group
            css={css`
              width: 300px;
              align-self: start;
              margin-left: 20px;
              margin-right: 10px;
            `}
          >
            <Segment.Header>
              <Title data-testid="descriptor-title">Descriptor </Title>
              <TitleRight data-testid="descriptor-name">
                {descriptorName}
              </TitleRight>
            </Segment.Header>
            <Segment
              style={{
                maxHeight,
                position: 'relative',
                paddingRight: 0,
              }}
            >
              <div
                style={{
                  maxHeight: `${maxHeight - paddingBottom - 50}px`,
                  overflowY: 'auto',
                }}
              >
                <div style={{ marginRight: '14px' }}>
                  {Object.keys(editableDescriptor ?? [])?.length > 0 &&
                    Object.keys(editableDescriptor)
                      .filter((propertyName: string) => {
                        const excludeFields =
                          property?.format?.type ===
                          PropertyFormatType.AiPluginDescriptor
                            ? PLUGIN_EXCLUDED
                            : TEMPLATE_EXCLUDED
                        return !excludeFields.includes(propertyName)
                      })
                      .map((propertyName: string) => {
                        const isExecutionMode =
                          propertyName === 'executionOutput' &&
                          editableDescriptor.executionMode.value ===
                            DescriptorExecutionMode.async
                        return (
                          <React.Fragment
                            key={`${editableDescriptor[propertyName].name}`}
                          >
                            {!isExecutionMode && (
                              <FormGrid
                                key={`${editableDescriptor[propertyName].name}`}
                              >
                                <Field
                                  data-testid={`${editableDescriptor[propertyName].name}-label`}
                                >
                                  {editableDescriptor[propertyName].name}
                                </Field>
                                <PropertyInput
                                  isClearable={false}
                                  isSearchable={false}
                                  property={editableDescriptor[propertyName]}
                                  value={editableDescriptor[propertyName].value}
                                  setFieldValue={(name, value) => {
                                    changeProperty(name, value)
                                  }}
                                  isError={!isEmpty(errors?.[propertyName])}
                                />
                                {errors?.[propertyName] && (
                                  <SmallError error={errors?.[propertyName]} />
                                )}
                              </FormGrid>
                            )}
                          </React.Fragment>
                        )
                      })}
                  <Title style={{ marginBottom: '8px' }}>Parameters</Title>
                  <div
                    style={{
                      display: 'flex',
                      gap: 10,
                      flexDirection: 'column',
                    }}
                  >
                    {editableDescriptor?.parameters?.length > 0 ? (
                      editableDescriptor?.parameters?.map(parameter => {
                        return (
                          <DescriptorParameters
                            key={parameter.name}
                            parameter={parameter}
                            errors={errors?.parameters?.[parameter.name]}
                            setDescriptorParameters={setDescriptorParameters}
                            removeDescriptorParameters={
                              removeDescriptorParameters
                            }
                          />
                        )
                      })
                    ) : (
                      <div data-testid="no-parameters"> No parameters </div>
                    )}
                  </div>
                </div>
              </div>
              {!isEmpty(errors?.schema) && (
                <div
                  ref={errorDivRef}
                  style={{
                    marginTop: '10px',
                    maxHeight: '200px',
                    overflowY: 'auto',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <SmallErrorMessage
                    style={{ marginRight: '14px' }}
                    data-testid="descriptor-error"
                  >
                    {errors?.schema}
                    {errors?.descriptor}
                  </SmallErrorMessage>
                </div>
              )}
            </Segment>
            <Modal.Actions style={{ zIndex: 9999 }}>
              <Button
                data-testid="close-descriptor-button"
                outline
                kind="secondary"
                onClick={() => close()}
              >
                Close
              </Button>
              <Button
                data-testid="delete-descriptor-button"
                kind="danger"
                onClick={() => deleteDescriptor()}
              >
                Delete
              </Button>
              <Button
                data-testid="save-descriptor-button"
                kind="primary"
                onClick={() => save()}
              >
                Save
              </Button>
            </Modal.Actions>
          </Segment.Group>
        )}
      {shouldShowDescriptor &&
        !isEmpty(descriptorName) &&
        isEmpty(editableDescriptor) && (
          <Segment.Group
            css={css`
              width: 300px;
              align-self: start;
              margin-left: 20px;
              margin-right: 10px;
            `}
          >
            <Segment.Header>
              <Title>Descriptor </Title>
            </Segment.Header>
            <Segment>
              <div data-testid="no-descriptor">No descriptor generated</div>
            </Segment>
          </Segment.Group>
        )}
    </div>
  )
}
const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
`
const FormGrid = styled(TwoColumnGrid)`
  grid-column-gap: 0.7em;
  grid-row-gap: 0.5em;
  margin-bottom: 0.5em;
`
const Field = styled.div`
  font-size: 0.9em;
`
const Title = styled.div`
  flex: 1;
  font-weight: bold;
`
export default DescriptorProperties
